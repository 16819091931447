<template>
  <!--begin::Toolbar wrapper-->
  <div class="d-flex align-items-stretch flex-shrink-0">
    
    <!--begin::Notifications-->
    <div class="d-flex align-items-center ms-1 ms-lg-3">
      <!--begin::Menu-->
      <!--end::Menu-->
    </div>
    <!--end::Notifications-->
    <div
      class="d-flex align-items-center ms-1 ms-lg-3"
    >
    <v-idle
      @idle="onidle"
      :loop="true"
      :wait="5"
      :duration="1800" 
      class="d-none"
      />

    </div>

    <div
      class="d-flex align-items-center ms-1 ms-lg-3"
    >
      <b>{{ state.societe }}</b>
    </div>

    

    <!--begin::User-->
    <div
      class="d-flex align-items-center ms-1 ms-lg-3"
      id="kt_header_user_menu_toggle"
    >
      <!--begin::Menu-->
      <div
        class="cursor-pointer symbol symbol-30px symbol-md-40px"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <div class="symbol-label fs-2 fw-bold text-primary">
          {{ state.initialContact }}
        </div>
      </div>
      <KTUserMenu></KTUserMenu>
      <!--end::Menu-->
    </div>
    <!--end::User -->

  </div>
  <!--end::Toolbar wrapper-->
</template>

<script lang="ts">
import { defineComponent, watch, reactive, onMounted } from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import mAxiosApi from "@/api";
import { ElMessageBox } from 'element-plus'


export default defineComponent({
  name: "topbar",
  components: {
    KTUserMenu,
  },
  emits: ["reloadRouter"],

  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();

    const reloadRouter = () => {
      emit("reloadRouter", 1);
    }

    const state = reactive({
      initialContact: "",
      societe: "test jle",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      contact: {} as any,
      myList: [] as any,
      loggedOut: false,
    });

    function varState() {
      if (!store.getters.isUserAuthenticated) return false;
      state.contact = store.getters.currentUser;

      state.societe = store.getters.currentUser.societeCodeArk ? store.getters.currentUser.societeCodeArk : "";

      state.initialContact =
        (state.contact.us_prenom ? state.contact.us_prenom.charAt(0).toUpperCase() : "") +
        (state.contact.us_nom ? state.contact.us_nom.charAt(0).toUpperCase() : "");
    }

    onMounted(async () => {
      state.loggedOut = false;
      let myListLiv = {} as any;
      //myListLiv = await getAxios("/getMyRole");
      state.myList = myListLiv;

    });

    watch(
      () => store.getters.currentUser,
      function () {
        varState();
      }
    );

    if (!store.getters.isUserAuthenticated) {
      // router.push({ name: "sign-in" });
    } else {
      varState();
    }

    const onidle= () => {
      signOut();
    };

    const adminApp = () => {
      router.push({ name: "admin" });
    }

    const signOut = () => {
      if (!state.loggedOut) {
        state.loggedOut = true;
        const refCodeArk = store.getters.currentUser.refCodeArk;
        
        mAxiosApi.post("/logout");
        store
        .dispatch(Actions.LOGOUT)
        .then(() => {   
          router.push({ name: "sign-in", params: { 'codeArk': refCodeArk } }).then(() => {   
            ElMessageBox.alert("En raison d’une période d’inactivité, vous avez été automatiquement déconnecté.", 'Déconnexion automatique', {
              confirmButtonText: 'OK'
            })
          });
        });
      }
    };

    return { state, reloadRouter,adminApp,onidle };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>
