<template>
  <div v-if="!state.loaderEnabled">
    <span class="fs-5"><b>Organisations</b></span>
    <el-select
      v-model="state.valueOrga"
      placeholder="Select"
      size="large"
      style="width: 240px"
      class="mx-4"
      @change="changeOrga(state.valueOrga)"
    >
      <el-option
        v-for="item in state.options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";

import mAxiosApi from "@/api";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { ElNotification } from "element-plus";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Overview",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();

    const state = reactive({
      orga: [] as any,
      options: [] as any,
      valueOrga: 0,
      loaderEnabled: true,
      defaultOrga: null,
    });

    const changeOrga = (id) => {
      store.dispatch(Actions.SET_CURRENT_ORG, {
        idOrg: id,
      });
    };

    onMounted(async () => {
      const me = await getAxios("/me");
      const mOrga = await getAxios("/orga");
      if (mOrga.errors) {
        ElNotification({
          title: "Error",
          message:
            "Il semble y avoir une erreur avec l'API, veuillez vérifier votre clé, svp",
          type: "error",
        });
        router.push({ name: "profil" });
      } else if (me["us_default_organisation"] !== null) {
        state.options = mOrga;
        state.valueOrga = me["us_default_organisation"];
        state.loaderEnabled = false;
        changeOrga(state.valueOrga);
      } else {
        state.options = mOrga;
        state.valueOrga = mOrga[0].value;
        state.loaderEnabled = false;
        changeOrga(state.valueOrga);
      }
    });

    return {
      state,
      changeOrga,
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>
