<template>
  <!--begin::Header-->
  <div id="kt_header" style="" class="header align-items-stretch">
    <!--begin::Container-->
    <div
      :class="{
        'container-fluid': headerWidthFluid,
        'container-xxl': !headerWidthFluid,
      }"
      class="d-flex align-items-stretch justify-content-between"
    >
      <!--begin::Aside mobile toggle-->
      <div
        class="d-flex align-items-center d-lg-none ms-n3 me-1"
        title="Show aside menu"
      >
        <div
          class="btn btn-icon btn-active-light-primary"
          id="kt_aside_mobile_toggle"
        >
          <span class="svg-icon svg-icon-2x mt-1">
            <inline-svg src="media/icons/duotune/abstract/abs015.svg" />
          </span>
        </div>
      </div>
      <!--end::Aside mobile toggle-->

      <!--begin::Mobile logo-->
      <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
        <a href="#" class="d-lg-none">
          <img alt="Logo" src="media/logos/logo_arkheia_rvb.png" class="h-30px" />
        </a>
      </div>
      <!--end::Mobile logo-->

      <!--begin::Wrapper-->
      <div
        class="
          d-flex
          align-items-stretch
          justify-content-between
          flex-lg-grow-1
        "
      >
        <!--begin::Navbar-->
        <div class="d-flex align-items-stretch" id="kt_header_menu_nav">
          <!--begin::Page title-->
          <div
            data-kt-swapper="true"
            data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
            class="
              page-title
              d-flex
              align-items-center
              flex-wrap
              me-3
              mb-5 mb-lg-0
            "
          >
            <a
              class="btn btn-light me-3"
              @click="backRoute()"
              v-if="displayBack()"
              ><i class="fas fa-arrow-left fs-4 me-2"></i> Retour</a
            >
            <Orga></Orga>
          </div>
          <!--end::Page title-->
        </div>
        <!--end::Navbar-->

        <!--begin::Topbar-->
        <div class="d-flex align-items-stretch flex-shrink-0">
          <img src="https://meraki.cisco.com/wp-content/uploads/2020/04/cisco-meraki-logo.png" style="width: 120px; height: 23.4px; margin-top: 18px; ">
          <KTTopbar @reloadRouter="reloadRouter($event)"></KTTopbar>
        </div>
        <!--end::Topbar-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Header-->
</template>

<script lang="ts">
import { defineComponent, onMounted, watch, reactive } from "vue";
import { useStore } from "vuex";
import KTTopbar from "@/layout/header/Topbar.vue";
import Orga from "@/views/still/meraki/orga.vue";
import { useRouter, useRoute } from "vue-router";

import mAxiosApi from "@/api";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTTopbar,
    Orga
  },
  emits: ["reloadRouter"],

  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const breadcrumbs = store.getters.pageBreadcrumbPath;


    const state = reactive({
      bordSeq: store.getters.bordSeq,
      bordType : store.getters.bordType,
      menuId: 1,
    });



    // localStorage.setItem('storedData', this.input)

    watch(()=>store.getters.bordSeq, function() {
        state.bordSeq = store.getters.bordSeq;
        state.bordType = store.getters.bordType;
        changeMenu();
    });

    watch(()=>route.path, function() {
        changeMenu();
    });

    const affBord = (type) => {
      if (type == state.bordType) router.push({ name: "bord" });
    }

    const affDashboard = () => {
      localStorage.setItem('filterBordereau', "");
      
      if (route.path.indexOf("/board/overview") == -1 ) router.push({ name: "board" });
    }

   const changeMenu = () => {
      if (route.path.indexOf("/board/overview") >= 0 ) {
        state.menuId = 1;
        return;
      }
      if (route.path == "/bord/articles" || route.path == "/bord/identification" || route.path == "/bord/description" || route.path == "/bord/signatures") {
        if (state.bordType == 'versement') state.menuId = 2;
        if (state.bordType == 'élimination') state.menuId = 3;
        return;
      }
      state.menuId = -1;
   }

    onMounted(async () => {
        //
        changeMenu();
    });

    const reloadRouter = () => {
      emit("reloadRouter", 1);
    }

    const backRoute = () => {
      router.go(-1);
    };

    const displayBack = () => {
      const mPath = route.path as any;
      // if (mPath.indexOf("/commande/") === 0) return true;
      return false;
    };

    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      breadcrumbs,
      backRoute,
      displayBack,
      reloadRouter,
      state,
      affBord,
      affDashboard
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  // console.log(response.data);
  return response.data;
}

</script>
