<template>
  <!--begin::Menu wrapper-->
  <div
    id="kt_aside_menu_wrapper"
    ref="scrollElRef"
    class="hover-scroll-overlay-y my-5 my-lg-5"
    data-kt-scroll="true"
    data-kt-scroll-activate="{default: false, lg: true}"
    data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
    data-kt-scroll-height="auto"
    data-kt-scroll-offset="0"
    data-kt-scroll-wrappers="#kt_aside_menu"
  >
    <!--begin::Menu-->
    <div
      id="#kt_header_menu"
      class="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
      data-kt-menu="true"
    > 
      <template v-for="(item, i) in state.MainMenuConfig" :key="i">
        <div v-if="item.heading && item.visible" class="menu-item">
          <div class="menu-content pt-8 pb-2">
            <span class="menu-section text-muted text-uppercase fs-8 ls-1 text-bold" style="font-size: 1.100rem !important;  font-weight: 900;  color: #fff !important;"><b> {{ item.heading }}</b>
            </span>
          </div>
        </div>
        <template v-for="(menuItem, j) in item.pages" :key="j">
          <template v-if="menuItem.heading && menuItem.visible ">
            <div class="menu-item">
              <router-link
                v-slot="{ isActive, isExactActive }"
                :to="menuItem.route"
              >
                <a
                  :class="[isActive && 'active', isExactActive && 'active']"
                  class="menu-link " style="border-radius: 0.275rem;"
                  @click="reloadRouter(menuItem)"
                >
                  <span class="menu-title"> {{
                    translate(menuItem.heading)
                  }}</span>
                </a>
              </router-link>
            </div>
          </template>
          <div
            v-if="menuItem.sectionTitle"
            :class="{ show: hasActiveChildren(menuItem.route) }"
            class="menu-item menu-accordion"
            data-kt-menu-sub="accordion"
            data-kt-menu-trigger="click"
          >
            <span class="menu-link">
              <span
                v-if="menuItem.svgIcon || menuItem.fontIcon"
                class="menu-icon"
              >
                <i
                  v-if="asideMenuIcons === 'font'"
                  :class="menuItem.fontIcon"
                  class="bi fs-3"
                ></i>
                <span
                  v-else-if="asideMenuIcons === 'svg'"
                  class="svg-icon svg-icon-2"
                >
                  <inline-svg :src="menuItem.svgIcon" />
                </span>
              </span>
              <span class="menu-title">{{
                translate(menuItem.sectionTitle)
              }}</span>
              <span class="menu-arrow"></span>
            </span>
            <div
              :class="{ show: hasActiveChildren(menuItem.route) }"
              class="menu-sub menu-sub-accordion"
            >
              <template v-for="(item2, k) in menuItem.sub" :key="k">
                <div v-if="item2.heading" class="menu-item">
                  <router-link
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    :to="item2.route"
                  >
                    <a
                      :class="[isActive && 'active', isExactActive && 'active']"
                      :href="href"
                      class="menu-link"
                      @click="navigate"
                    >
                      <span class="menu-bullet">
                        <span class="bullet bullet-dot"></span>
                      </span>
                      <span class="menu-title">{{
                        translate(item2.heading)
                      }}</span>
                    </a>
                  </router-link>
                </div>
                <div
                  v-if="item2.sectionTitle"
                  :class="{ show: hasActiveChildren(item2.route) }"
                  class="menu-item menu-accordion"
                  data-kt-menu-sub="accordion"
                  data-kt-menu-trigger="click"
                >
                  <span class="menu-link">
                    <span class="menu-bullet">
                      <span class="bullet bullet-dot"></span>
                    </span>
                    <span class="menu-title">{{
                      translate(item2.sectionTitle)
                    }}</span>
                    <span class="menu-arrow"></span>
                  </span>
                  <div
                    :class="{ show: hasActiveChildren(item2.route) }"
                    class="menu-sub menu-sub-accordion"
                  >
                    <template v-for="(item3, k) in item2.sub" :key="k">
                      <div v-if="item3.heading" class="menu-item">
                        <router-link
                          v-slot="{ href, navigate, isActive, isExactActive }"
                          :to="item3.route"
                        >
                          <a
                            class="menu-link"
                            :class="[
                              isActive && 'active',
                              isExactActive && 'active',
                            ]"
                            :href="href"
                            @click="navigate"
                          >
                            <span class="menu-bullet">
                              <span class="bullet bullet-dot"></span>
                            </span>
                            <span class="menu-title">{{
                              translate(item3.heading)
                            }}</span>
                          </a>
                        </router-link>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
      </template>
      
    </div>
    <!--end::Menu-->
  </div>
  <!--end::Menu wrapper-->
</template>

<style lang="scss">
.aside-menu .menu .menu-sub .menu-item a a.menu-link {
  padding-left: calc(0.75rem + 25px);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 0 0 100%;
  transition: none;
  outline: none !important;
}

.aside-menu .menu .menu-sub .menu-sub .menu-item a a.menu-link {
  padding-left: calc(1.5rem + 25px);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 0 0 100%;
  transition: none;
  outline: none !important;
}
</style>

<script lang="ts">
import { defineComponent, onMounted, ref, reactive,watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "kt-menu",
  components: {},

  emits: ["reloadRouter"],

  setup(props, {emit}) {

    const router = useRouter();
    const route = useRoute();
    
    const state = reactive({
      MainMenuConfig: [] as any,
      categMenu: 'dashboard',
      bordSeq :0,
      bordType : '',

    });


    watch(
      () => route.path,
      () => {
        testRoute();
      }
    );

    const testRoute = () => {
        if (route.path.indexOf('/bord/')> -1) state.categMenu = 'bordereau';
        if (route.path.indexOf('/board/')> -1) state.categMenu = 'dashboard';
        if (route.path.indexOf('/admin')> -1) state.categMenu = 'admin';
        if (route.path.indexOf('/importProd')> -1) state.categMenu = 'admin';
        if (route.path.indexOf('/parametrage')> -1) state.categMenu = 'admin';
    }

    const reloadRouter = async (item) => {
      
      if (item.route.indexOf("/board/overview") > -1) {
        if(item.route != "/board/overview") {
          window.localStorage.setItem('filterBordereau', item.route);
        } else {
          window.localStorage.setItem('filterBordereau', "");
        }
        
        router.push({ path: item.route }).then(() => {
          emit("reloadRouter", 1);
        })
      } else {
        router.push({ path: item.route });
      }

    }

    const { t, te } = useI18n();
    
    const scrollElRef = ref<null | HTMLElement>(null);

    const getMenu = async () => {

      state.MainMenuConfig = [
            {
                "heading": "Gestion des réseaux ",
                "visible": true,
                "pages": [
                    {
                        "heading": "Liste des réseaux",
                        "visible": true,
                        "svgIcon": "media/icons/duotune/art/art002.svg",
                        "route": "/networks"
                    },
                    {
                        "heading": "Ajouter un réseau",
                        "visible": true,
                        "svgIcon": "media/icons/duotune/finance/fin006.svg",
                        "route": "/addNetwork"
                    },
                ]
            },
            {
                "heading": "Règles Firewall",
                "visible": true,
                "pages": [
                    {
                        "heading": "Vue d'ensemble",
                        "visible": true,
                        "svgIcon": "media/icons/duotune/art/art002.svg",
                        "route": "/overviewFirewall"
                    },
                ]
            },
            /*
            {
                "heading": "Matériels",
                "visible": true,
                "pages": [
                    {
                        "heading": "Liste du matériel",
                        "visible": true,
                        "svgIcon": "media/icons/duotune/art/art002.svg",
                        "route": "https://n213.meraki.com/o/3_mQkd/manage/organization/overview#t=device"
                    },
                ]
            },
            */
        ];
    }

    onMounted(async () => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }

      testRoute();
      getMenu();    

      let nUser = await getAxios("/me");

      if(nUser.us_role == "ADM") {
        state.MainMenuConfig.push(
          {
                "heading": "Paramètres",
                "visible": true,
                "pages": [
                    {
                        "heading": "Paramétrer l'application",
                        "visible": true,
                        "svgIcon": "media/icons/duotune/art/art002.svg",
                        "route": "/admin"
                    },
                ]
            }
        );
      }

    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      state,
      asideMenuIcons,
      version,
      translate,
      reloadRouter
    };
  },
});


export async function getAxios(request: string): Promise<any> {

  const response = await mAxiosApi.get(request);
  return response.data;

}

</script>
