
import { defineComponent, onMounted, ref, reactive,watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "kt-menu",
  components: {},

  emits: ["reloadRouter"],

  setup(props, {emit}) {

    const router = useRouter();
    const route = useRoute();
    
    const state = reactive({
      MainMenuConfig: [] as any,
      categMenu: 'dashboard',
      bordSeq :0,
      bordType : '',

    });


    watch(
      () => route.path,
      () => {
        testRoute();
      }
    );

    const testRoute = () => {
        if (route.path.indexOf('/bord/')> -1) state.categMenu = 'bordereau';
        if (route.path.indexOf('/board/')> -1) state.categMenu = 'dashboard';
        if (route.path.indexOf('/admin')> -1) state.categMenu = 'admin';
        if (route.path.indexOf('/importProd')> -1) state.categMenu = 'admin';
        if (route.path.indexOf('/parametrage')> -1) state.categMenu = 'admin';
    }

    const reloadRouter = async (item) => {
      
      if (item.route.indexOf("/board/overview") > -1) {
        if(item.route != "/board/overview") {
          window.localStorage.setItem('filterBordereau', item.route);
        } else {
          window.localStorage.setItem('filterBordereau', "");
        }
        
        router.push({ path: item.route }).then(() => {
          emit("reloadRouter", 1);
        })
      } else {
        router.push({ path: item.route });
      }

    }

    const { t, te } = useI18n();
    
    const scrollElRef = ref<null | HTMLElement>(null);

    const getMenu = async () => {

      state.MainMenuConfig = [
            {
                "heading": "Gestion des réseaux ",
                "visible": true,
                "pages": [
                    {
                        "heading": "Liste des réseaux",
                        "visible": true,
                        "svgIcon": "media/icons/duotune/art/art002.svg",
                        "route": "/networks"
                    },
                    {
                        "heading": "Ajouter un réseau",
                        "visible": true,
                        "svgIcon": "media/icons/duotune/finance/fin006.svg",
                        "route": "/addNetwork"
                    },
                ]
            },
            {
                "heading": "Règles Firewall",
                "visible": true,
                "pages": [
                    {
                        "heading": "Vue d'ensemble",
                        "visible": true,
                        "svgIcon": "media/icons/duotune/art/art002.svg",
                        "route": "/overviewFirewall"
                    },
                ]
            },
            /*
            {
                "heading": "Matériels",
                "visible": true,
                "pages": [
                    {
                        "heading": "Liste du matériel",
                        "visible": true,
                        "svgIcon": "media/icons/duotune/art/art002.svg",
                        "route": "https://n213.meraki.com/o/3_mQkd/manage/organization/overview#t=device"
                    },
                ]
            },
            */
        ];
    }

    onMounted(async () => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }

      testRoute();
      getMenu();    

      let nUser = await getAxios("/me");

      if(nUser.us_role == "ADM") {
        state.MainMenuConfig.push(
          {
                "heading": "Paramètres",
                "visible": true,
                "pages": [
                    {
                        "heading": "Paramétrer l'application",
                        "visible": true,
                        "svgIcon": "media/icons/duotune/art/art002.svg",
                        "route": "/admin"
                    },
                ]
            }
        );
      }

    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      state,
      asideMenuIcons,
      version,
      translate,
      reloadRouter
    };
  },
});


export async function getAxios(request: string): Promise<any> {

  const response = await mAxiosApi.get(request);
  return response.data;

}

